<template>
  <div
    :class="{
      hidden: showOnlyIfLocked && !locked,
      'inline-flex': !showOnlyIfLocked
    }"
    class="flex items-center justify-center font-bold p-0.5 text-sm my-auto self-center"
    v-tooltip="tooltip"
  >
    <fa-icon
      :icon="locked ? 'lock' : 'lock-open'"
      class="ml-1 text-gray-500 dark:text-neutral-400 fa-fw"
      size="sm"
    />
  </div>
</template>
<script>
export default {
  name: 'content-locked-badge',
  props: {
    locked: {
      default: false,
      type: Boolean
    },
    showOnlyIfLocked: {
      default: false,
      type: Boolean
    },
    hideTooltip: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    tooltip() {
      if (this.hideTooltip) {
        return ''
      }

      return this.locked
        ? 'This asset is locked, no changes can be made to it'
        : 'This asset is unlocked and can be freely modified'
    }
  }
}
</script>
